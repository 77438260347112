import { stripIndents } from 'common-tags';
import { COMMAND_NAMES } from './command-constants';

export const whoami = () => {
  const command = {
    name: COMMAND_NAMES.WHOAMI,
    description: 'about me ',
    run: () => stripIndents`
      --

      Hi I'm Ryan:)


      Software Engineer @ Capital One | Growth Engineer | AWS Certified Developer | Accessibility



      --
    `,
  };

  return command;
};
