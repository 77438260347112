import { COMMAND_NAMES } from './command-constants';

export const linkedin = () => {
  const command = {
    name: COMMAND_NAMES.LINKEDIN,
    description: 'connect with me mang ',
    run: () => {
      // Open twitter in new tab
      window.open('https://www.linkedin.com/in/ryansbrennan/', '_blank');
    },
  };

  return command;
};
