import { COMMAND_NAMES } from './command-constants';

export const photo = () => {
  const command = {
    name: COMMAND_NAMES.PHOTO,
    description: ``,
    run: () => `
      just squint your eyes and use your imaginination. I'm there, I swear;-) 

    @@@@@@@&&&@&&&&&&B#&&#&&@&&#BB&&&&&&&&##BBBGP5P5YY5PYJ?JGGPP#&&&@&@@&&&&&&&&&&&&&&&&&&&&&&
    @@@@@@@@@&&&&&&&&&B#&#P#&#GB#&&&&&#########BGPP5Y7??JJ?77?J5PPPGB#&&&&&&&&&&&&&&&&&&&&&&&&
    @@@@@@@@@@@&@@@&&&&GB&5GBB&&&&########BBGPYJ?7!~~^^::^^^^^~!?JYJY5G#&&&&&&&&&&&&&&&&&&&&&&
    @@@@@@@@@@@@&@@@@&&#!PPB&&&#####&&&#BGY7~^::.................:^!JY5PG&&&&@@&&&&&&&&&&&&&&&
    @@@@&&&&&@@@&&&&&@#5JG#&&###&&&&&&#GJ~^::::::::::................^JGB&@@@@&&&&&&&&&&&&&&&&
    &&&&&&&&&&&&&&&&&B?JB&&&&&&&&&&&#B5!:::::::::::::..................^5&@@@@&&&&&&&&&&&&&&&&
    &&&&&&&&&&&&&&&&B7?##&&&&@&&&&BGPJ!^::::::::::::::...................?@&&&&&&&&&&&&&&&&&&&
    &&&&&&&&&&&&&&&#!Y###&&@@&###BPYY?~^^:::::::::::......................?&&&&&&&&&&&&&&&&&&&
    ############&&#?5####&&&##BB#B5YY?!~^:::..:::::::......................G@&&&&&&&&&&&&&&&&&
    BBBB#BBBB#####BP##B#&&#####&##PJJ?7!~^^:....::::::.....................:G&&&&&&&&&&&&&&&&&
    GGBBBBBBBBBGGBPBBBB###&&##&&##GY???7!~^::::..::^::::::..:^^^:............?B##&&&&&&&&&&&&&
    GGGGGGGGGGPPGPB#BBBB#&&&&&##&#BGYYYJ?!~^:^::^^~!!77!7?YY555Y?!^:...:.:^!^.^B&&&&&&&&&&&&&&
    GGGGGGGGGGPPGG&B#BB#&@@@&##&&#&#BPPJ~^^^^^^^!7???7!!7?YPGGPPY?~:...~7JYYJ~:5@&&&&&&&&&&&&@
    &&&&&&&&&&#BGBB##B#&@@@@&&&&##&#BGY!::^^^^^!777!7JYYY?5BBP5Y7~^:...!??JY?^:B@@@@@@@@@@@@@@
    &&&&&&&&&&&BP#B##&&@@@@@@&&&####BPY!:::^^:^^^~7?JJ?!::~77?~^^::^::..:7PYJ~?&@@@@@@@@@@@@@@
    &&&&&&&&&&BBGB#B#&@@@@&&######BBBPY!^:^:::....:...........:::::^^^^:.:77!Y&&&@@@@@@@@@@@@@
    &&&&&&&&&B##G#&#&&&&##B##BBGBBBBBGP7^^^:::...................::^^^^::...:&@&&&&&&&&&&@&&&#
    #&&&&&&#GB#GG&&#&&&#GGGGGP77?PG#GBBP7^^^::::................:::^~^^::....J@&&&&&@@@&&#BBBB
    ###&&&&PPBBGB#&&&##GYYYJ?J77!7YBGPBP5~^^^:::::.............::^~^~7777!~~. P@&&&@@@@&&P5PBB
    ###&&&BJGGPB##&&&B&B7?J7~JG?^~7PGGGPJ~^^^::::::::.........:^~7!?G#GG5YJ^  !@&@@@@@@@&?7PBG
    ######G?PYPB###&&##&Y?J7JY?7~^!5BGGGY!~~^^^^^^^^:::......:^:^!???77?!!^.  ?##BBBBBGPPGG5J?
    #######7Y5GB#&#&&&B#&Y?!!!~77~75BB#BGYJJ!!~~^^^^^^^::..:^~~~7!755Y?!^^^:. JGPPP55??PGY7???
    ######&Y55GB#&#&&&#GB&5?77~~~~?GB####BP5YYJ7~~^^^^^::^^7J55Y5Y5GPJ?7~^~^^:G&&&&&&&&#?7J??7
    BBB##B#BGPGBB##&&&&#G##B5J7~~~JBB###BBBG5P5Y??77~!~!7J5PG5PPP55PYJ5JJJY7!!75BBBBBB5~?J??7!
    #BBB#B#B55BB####&&&BB&###&&BB#&#GB#####BGGP5YYJJJYY555GGPJGGBBGGGGGGGGG5??775GGGGY~7J?77!!
    BBBBBG#PPYG##&&##&#B#&&&&&&@@@&#BBB######BBGGGPPGGGPGGGPY5YJJ?7!~^:^:::7Y?775GGPY?!JJJ!!!~
    B##BGGB55PYP##&&BBB#&&&&&@@@@&@#BB####&&#####BBGGBGGGGP55Y7!!!!~^^^^^:..~~^75GPY577??J?~!7
    BBBP5GGGY555###&BG#&&&&&&@@&@@@&######&&&&#&&##BBBBGPPPPPJYY?7?!!J~^^^:^^7!?55JY5?7?7!?7~?
    BGGBY?YGB#&&##&&&#&&&&&&&@&@@@@&&&###&&#&&&&&&######B#GBP5PP5YJ?YJ!^7~^!!!??7JYYYJ~!!~~77Y
    GGB#G5GB###BB&&&@&&&&&&#&&@@@@@@&&&&##&&&&&&&&&&&&&##B#BGGG55JJ??7!7!!^~?~~55YPPJJ!^~^^~JY
    GBB##GGB#BB&&&&&&@&#&&#&&@@&&&@@&&&&&&&&&&&&&&&&&&#B#BBGPGPJJ77J77?J!~:^?~:G#B#B???^~^^J55
    BBGB&&BGBB#&&##&&&&#&&BB#@#&#&&&&&&&&@&&&&&&&&&&###BGGGP5P55?J???JJJ!!::~~~B#BGP?7!!~~7GPP
    GGPG#&&BB&&GGGB&&@&##@&##&#&&&&&&&&&&&&&&&&&&&&&&###GGG5JPPJY5YJ5??!7Y!^~~?#BBGJ?J!~~^!GBY
    BBBBB###B#BBB####@@@&&&&&&&&&&@&&&###&&&&&&&&&&&&&&&#BP5555?55PPY75755?^!!P&&#B?!?!^^^!5PY
    ###BB#&&#BB&&&&&#@@@@@&&@@@@@@&&&#B#######&&&&&&&&&&#&&GY?YJ?P5PYJ5PG5!!7?B&#B&&#G5?~!5P5Y
    &&&#BB&&&#&@&&&&&&@@@@@@@@@@@@&&&#BBBBBBBBB###&&&&&&####BY?J?GY7JYGPJJ?5Y&&&&&&&&@&&&&&#BB
    &&&&&#&@@&&&&&&&@@@@@@@@@@@@@@&&&#BGGBBBBGGBBB##&&&&&&&##G??JJY?7PYJYYJJB@&&@&&&&&&&&&&&&&
    #BBBB#@@@@@@@@@@@@@@@@@@@@@@@@###B#BGGGGGGGPGGGBB##&&&&&##BP5Y5Y5Y!~!~:!#@&&@&&&&@@&&&&&&&
    &##BB#@@@@@@@@@@@@@@@@@@@@@@@&BBGGBBGPPPGGPPPPGBBBBBBB####BBPPP5?~::::^Y&@@@@@@&@@@@@&&&&&
    &&##B&@@@@@@@@@@@@@@@@@@@@@@@&GPY5GGG5555PPPPPGBBBBBBBBBGPP5555J!^:::^?&@@@@@@@@@@@@@@@@@@
    &&&@@@@@@@@@@@@@@@@@@@@@@@@@@#5YYY55555555PPPGGGGGGPPPGGGPPPPPJ!~^::^7B@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@#5J?7???JJJJY55PGGP5YYY5PPPPPPP5J!~^:::~5@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@&G?!!!!!!???JY5PP5YJJJY5PP5YJJJ?!^^:::^7&@@@@@@@@@@@@@@@@@@@@
  `
  };

  return command;
};
