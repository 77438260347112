import { COMMAND_NAMES } from './command-constants';

export const instagram = () => {
  const command = {
    name: COMMAND_NAMES.INSTAGRAM,
    description: 'make a new friend',
    run: () => {
      // Open twitter in new tab
      window.open('https://www.instagram.com/baconlollipops/', '_blank');
    },
  };

  return command;
};
