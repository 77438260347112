import { stripIndents } from 'common-tags';
import { COMMAND_NAMES } from '../command-constants';

export const contact = () => {
  const command = {
    name: COMMAND_NAMES.CONTACT,
    description: `say hi:)`,
    run: () => stripIndents`
    --

    phone:  +1 415.717.1515
    email:  rybrennan1@gmail.com
   

    --
  `
  };

  return command
}
