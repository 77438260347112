import { COMMAND_NAMES } from './command-constants';

export const music = {
  name: COMMAND_NAMES.MUSIC,
  description: ``,
  run: () => {
    return `

   rock on
`;
  },
};



// ___                                          ___
// __________________/  /                       __________________/  /
// | _    _______    /  /                       | _    _______    /  /
// |(_) .d########b. //)| _____________________ |(_) .d########b. //)|
// |  .d############//  ||        _____        ||  .d############//  |
// | .d######""####//b. ||() ||  [     ]  || ()|| .d######""####//b. |
// | 9######(  )#_//##P ||()|__|  | = |  |__|()|| 9######(  )#_//##P |
// | 'b######++#/_/##d' ||() ||   | = |   || ()|| 'b######++#/_/##d' |
// |  "9############P"  ||   ||   |___|   ||   ||  "9############P"  |
// |  _"9a#######aP"    ||  _   _____..__   _  ||  _"9a#######aP"    |
// | |_|      || (_) |_____||__| (_) || |_|      |
// |  ___..___________  ||_____________________||  ___..___________  |
// | |___||___________| |                       | |___||___________| |
// |____________________|                       |____________________|